angular.module("ngConstants", [])

.constant("host", "https://simonsaysai.com/")

.constant("ogUrl", "https://app.simonsaysai.com/")

.constant("PUBLIC_SITE_URL", "https://transcript.simonsaysai.com")

.constant("STORAGE_BASE_URL", "https://s3.amazonaws.com/public-media-transcript/")

.constant("PUBLIC_SITE_TRANSCRIPT_URL", "https://transcript.simonsaysai.com")

;